<template>
    <div class="addedit">
        <!-- 标题 -->
        <div class="vue_title title"> <i class="el-icon-arrow-left" @click="back"></i>{{ Object.values(info).length > 0 ?
            '编辑字幕' : '添加字幕' }}</div>
        <div class="addeditmain">
            <div class="formbox">
                <el-form ref="form" :model="form" label-width="100px" label-position="left" :rules="rules">
                    <el-form-item label="字幕名称" prop="name">
                        <el-input v-model="form.name" placeholder="请输入字幕名称"></el-input>
                    </el-form-item>
                    <el-form-item label="分类" prop="category_id">
                        <el-select v-model="form.category_id" placeholder="请选择分类">
                            <el-option v-for="item in classifyOptions" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="字幕缩略图" prop="thumbnail">
                        <img-upload :width="100" :height="100" type="img_1" @upload="upload($event, 'thumbnail')">
                            <div class="picture_img_upload">
                                <div class="img_yes" v-if="form.thumbnail">
                                    <el-image :src="form.thumbnail" fit="scale-down"></el-image>
                                    <div class='succ_btn'>
                                        <i class="el-icon-picture-outline-round" @click.stop="playImg(form.thumbnail)"></i>
                                        <i class="el-icon-circle-plus-outline"></i>
                                    </div>
                                </div>
                                <div class="img_no" v-else>
                                    <i class="el-icon-plus"></i>
                                    <div>上传图片</div>
                                </div>
                            </div>
                        </img-upload>
                    </el-form-item>
                    <el-form-item label="字幕背景图" prop="thumbnail">
                        <img-upload :width="100" :height="100" type="img_1" @upload="upload($event, 'backgroud_img')">
                            <div class="picture_img_upload">
                                <div class="img_yes" v-if="form.backgroud_img">
                                    <el-image :src="form.backgroud_img" fit="scale-down"></el-image>
                                    <div class='succ_btn'>
                                        <i class="el-icon-picture-outline-round" @click.stop="playImg(form.backgroud_img)"></i>
                                        <i class="el-icon-circle-plus-outline"></i>
                                    </div>
                                </div>
                                <div class="img_no" v-else>
                                    <i class="el-icon-plus"></i>
                                    <div>上传背景图片</div>
                                </div>
                            </div>
                        </img-upload>
                    </el-form-item>
                    <el-form-item label="状态" prop="status">
                        <el-switch v-model="form.status" :active-value="1" :inactive-value="2"></el-switch>
                    </el-form-item>
                </el-form>
            </div>
            <!-- 其他设置 -->
            <div class="otherbox">
                <p class="title">字幕配置设置</p>
                <div class="item-box">
                    <div class="item" style="align-items: center;">
                        <div class="item-l">对齐方式</div>
                        <div class="item-r">
                            <div class="r-selectAlign">
                                <div class="r-selectAlign-l otherStyle">
                                    <el-tooltip effect="dark" content="向左对齐" placement="top">
                                        <div class="left-align icon"
                                            :class="{ 'activemaincolor': otherTyle.textAlign == 'left' }"
                                            @click="otherTyle.textAlign = 'left'"></div>
                                    </el-tooltip>
                                </div>
                                <div class="r-selectAlign-c otherStyle">
                                    <el-tooltip effect="dark" content="居中对齐" placement="top">
                                        <div class="center-align icon"
                                            :class="{ 'activemaincolor': otherTyle.textAlign == 'center' }"
                                            @click="otherTyle.textAlign = 'center'"></div>
                                    </el-tooltip>
                                </div>
                                <div class="r-selectAlign-r otherStyle">
                                    <el-tooltip effect="dark" content="向右对齐" placement="top">
                                        <div class="right-align icon"
                                            :class="{ 'activemaincolor': otherTyle.textAlign == 'right' }"
                                            @click="otherTyle.textAlign = 'right'"></div>
                                    </el-tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="item" style="align-items: flex-start;">
                        <div class="item-l">字体设置</div>
                        <div class="item-r" :style="fontFamilyStyle">
                            <!-- 设置字体的样式 -->
                            <div class="r-fontFamily">
                                <el-select v-model="otherTyle.fontFamily" placeholder="请选择" size="mini">
                                    <el-option v-for="item in fontsList" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                                <div class="selectcolor">
                                    <el-color-picker v-model="otherTyle.color" size="mini"></el-color-picker>
                                </div>
                            </div>
                            <!-- 字体预览 -->
                            <div class="previewtxt">
                                <p>Aa Bb Cc</p>
                                <p>123456789</p>
                                <p>嗨! 欢迎使用云川数字人直播，很高兴认识你，非常荣幸能为您播报任意您想表达的内容，快来试一试吧！</p>
                            </div>
                            <!-- 设置字体大小 -->
                            <div class="r-StyleOne">
                                <div class="r-StyleOne-size">
                                    <el-tooltip effect="dark" content="字体大小" placement="top">
                                        <el-input-number v-model="otherTyle.fontSize" :min="1" :max="100" label="字体大小"
                                            size="mini"></el-input-number>
                                    </el-tooltip>
                                </div>
                                <div class="r-StyleOne-other">
                                    <!-- 是否粗体 -->
                                    <el-tooltip effect="dark" content="粗体" placement="top">
                                        <div class="item-other"
                                            :class="{ 'activemaincolor': otherTyle.fontWeight == 'blod' }"
                                            @click="changeBlod">B</div>
                                    </el-tooltip>
                                    <!-- 是否泄题 -->
                                    <el-tooltip effect="dark" content="斜体" placement="top">
                                        <div class="item-other"
                                            :class="{ 'activemaincolor': otherTyle.fontStyle == 'italic' }"
                                            @click="changeStyle">I</div>
                                    </el-tooltip>
                                </div>
                            </div>
                            <!-- 设置行间距 -->
                            <div class="r-StyleTwo">
                                <span>行间距</span>
                                <div class="r-StyleTwo-center">
                                    <el-slider v-model="otherTyle.lineHeight" input-size="mini" :min="1" :max="10"
                                        show-input>
                                    </el-slider>
                                </div>
                                <span>倍</span>
                            </div>
                            <!-- 设置字体的间距 -->
                            <div class="r-StyleTwo">
                                <span>字间距</span>
                                <div class="r-StyleTwo-center">
                                    <el-slider v-model="otherTyle.letterSpacing" input-size="mini" :min="1" :max="50"
                                        show-input>
                                    </el-slider>
                                </div>
                                <span>px</span>
                            </div>
                        </div>
                    </div>
                    <!-- 模版的样式 -->
                    <div class="item" style="align-items: flex-start;">
                        <div class="item-l">
                            模版样式
                        </div>
                        <div class="item-r">
                            <div class="itemtemplatebox">
                                <div class="itemtemplate" v-for="(item, z) in templatelist" :key="z"
                                    @click="selectStockColor(item)"
                                    :style="{ 'color': item.color, '--txtcolorshadow': item.stroke }">T</div>
                            </div>
                            <!-- 字体的粗细 -->
                            <div class="itemtemplatefontweight">
                                <span>粗细</span>
                                <div class="selectweight ">
                                    <el-slider v-model="otherTyle.strokeThickness" input-size="mini" :min="10" :max="50"
                                        show-input>
                                    </el-slider>
                                </div>
                                <div class="itemtemplatecolor">
                                    <el-color-picker v-model="otherTyle.stroke" size="mini"></el-color-picker>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 最下放的保存 -->
                    <div class="buttonGroup">
                        <el-button type="primary" @click="submit" :loading="loading"
                            v-if="Object.values(info).length == 0">保
                            存</el-button>
                        <el-button type="primary" @click="subEdit" :loading="loading"
                            v-if="Object.values(info).length > 0">编
                            辑</el-button>
                    </div>
                </div>
            </div>
        </div>
        <!-- 预览图片 -->
        <img-preview ref="img"></img-preview>
    </div>
</template>

<script>
import ImgPreview from '@/components/img_preview/index.vue'

export default {
    components: {
        ImgUpload: (resolve) => require(['@/components/file_upload/index'], resolve),
        ImgPreview
    },
    props: {},
    data() {
        return {
            classifyOptions: [], // 分类
            form: {
                category_id: '',
                name: '',
                thumbnail: '',
                backgroud_img: '',
                status: 1
            },
            rules: {
                name: [
                    { required: true, message: '请输入字幕名称', trigger: 'blur' },
                    {
                        min: 2,
                        max: 10,
                        message: '长度在 2 到 10 个字符',
                        trigger: 'blur'
                    }
                ],
                category_id: [
                    { required: true, message: '请选择分类', trigger: 'change' }
                ],
                status: [{ required: true, message: '请选择分类', trigger: 'blur' }],
                thumbnail: [{ required: true, message: '请上传图片', trigger: 'change' }],
                backgroud_img: [{ required: true, message: '请上传图片', trigger: 'change' }]
            },
            otherTyle: {
                textAlign: 'left', // 字体的排列方式
                fontSize: 14, // 字体的大小
                fontWeight: 'normal', // 字体的粗细
                fontStyle: 'normal', // 字体的样式
                lineHeight: 1, // 行间距
                letterSpacing: 1, // 字间距
                fontFamily: '', // 字体的名称
                color: '#000000', // 字体的颜色
                strokeThickness: 10, // 模版字体的粗细
                stroke: '' // 字体描边的颜色
            },

            fontsList: [
                { label: '默认字体', value: '' },
                { label: '刀隶体', value: 'ALIDL' },
                { label: '方圆体', value: 'ALIFYT' },
                { label: '东方大楷', value: 'ALIDFDY' },
                { label: '站酷高端黑', value: 'ALIZK' }
            ],
            templatelist: [
                { stroke: '#ffffff', strokeThickness: 10, color: '#000000' },
                { stroke: '#000000', strokeThickness: 10, color: '#FFDC38' },
                { stroke: '#E89286', strokeThickness: 10, color: '#FFFFFF' },
                { stroke: '#000000', strokeThickness: 10, color: '#B6DBF7' },
                { stroke: '#FA6B9D', strokeThickness: 10, color: '#FDD9E5' },
                { stroke: '#5A9AE6', strokeThickness: 10, color: '#C4EEFF' },
                { stroke: '#455C2C', strokeThickness: 10, color: '#C7CC56' },
                { stroke: '#40576B', strokeThickness: 10, color: '#96C2CD' },
                { stroke: '#FB3546', strokeThickness: 10, color: '#FFFFFF' },
                { stroke: '#E7C7C2', strokeThickness: 10, color: '#AD4939' }
            ],
            loading: false,
            info: {}
        }
    },
    computed: {
        fontFamilyStyle() {
            return {
                '--fontName': this.otherTyle.fontFamily,
                '--fontColor': this.otherTyle.color
            }
        }
    },
    mounted() {
        if (this.$route.query.item) {
            this.info = JSON.parse(this.$route.query.item)
            if (Object.values(this.info).length > 0) {
                for (const key in this.info) {
                    if (Object.hasOwn(this.form, key)) {
                        this.form[key] = this.info[key]
                    }
                }
            }
            var config = JSON.parse(this.info.config_json)
            if (Object.values(config).length > 0) {
                for (const key in config) {
                    if (Object.hasOwn(this.otherTyle, key)) {
                        this.otherTyle[key] = config[key]
                    }
                }
            }
        }
        this.getclassify()
    },
    methods: {
        back() {
            this.$router.go(-1)
        },
        upload(item, type) {
            this.form[type] = item.long_url
        },
        // 获取分类的列表
        getclassify() {
            this.$materialApi.material_caption_cat().then(res => {
                this.classifyOptions = res?.result?.cat_list ?? []
            })
        },
        // 更改字体的粗细
        changeBlod() {
            this.otherTyle.fontWeight = this.otherTyle.fontWeight === 'normal' ? 'blod' : 'normal'
        },
        // 更改字体的样式
        changeStyle() {
            this.otherTyle.fontStyle = this.otherTyle.fontStyle === 'normal' ? 'italic' : 'normal'
        },
        // 选择字体模版的颜色
        selectStockColor(item) {
            this.otherTyle.stroke = item.stroke
        },
        // 提交添加
        submit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.loading = true
                    this.$materialApi.material_caption_add({
                        ...this.form,
                        config_json: JSON.stringify(this.otherTyle)
                    }).then(res => {
                        this.loading = false
                        this.$message({
                            message: res.message,
                            type: res.code === 1000 ? 'success' : 'error'
                        })
                        if (res.code === 1000) {
                            //   this.$emit('reset')
                            this.back()
                        }
                    })
                }
            })
        },

        // 编辑
        subEdit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.loading = true
                    this.$materialApi.material_caption_edit(this.info.id, {
                        ...this.form,
                        config_json: JSON.stringify(this.otherTyle)
                    }).then(res => {
                        this.loading = false
                        this.$message({
                            message: res.message,
                            type: res.code === 1000 ? 'success' : 'error'
                        })
                        if (res.code === 1000) {
                            //   this.$emit('reset')
                            this.back()
                        }
                    })
                }
            })
        },
        // 预览图片
        playImg(url){
            this.$refs.img.openDialog(url)
        },
    }
}
</script>
<style lang="scss" scoped>
.succ_btn{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    padding: 0 10px;
    border-radius: 5px;
    background: rgba($color: #000000, $alpha: 0.5);
    display: none;
    align-items: center;
    justify-content: space-around;
    i{
        font-size: 26px;
        color: #fff;
    }
}
.img_yes:hover .succ_btn{
    display: flex;
}
.addedit {
    .title {
        i {
            cursor: pointer;
            margin-right: 10px;
            transition: all .3s ease;

            &:hover {
                font-weight: 800;
                color: $blueColor1;
                transition: all .3s ease;
            }
        }
    }

    .addeditmain {
        padding-left: 30px;

        .formbox {
            width: 30%;
            margin-top: 30px;
        }

        .otherbox {
            .title {
                font-size: 14px;
                color: #A9A9A9;
            }

            .item-box {
                margin-top: 20px;
            }

            .item {
                display: flex;
                // align-items: center;
                margin-bottom: 22px;

                .item-l {
                    // vertical-align: middle;
                    // float: left;
                    font-size: 14px;
                    color: #606266;
                    line-height: 40px;
                    width: 100px;
                }

                .r-selectAlign {
                    // position: relative;
                    border: 1px solid #ccc;
                    width: 180px;
                    height: 20px;
                    display: flex;
                    align-items: center;
                    padding: 5px 10px;

                    .r-selectAlign-l {
                        position: relative;
                    }

                    .left-align.icon {
                        color: #000;
                        position: absolute;
                        // margin-left: 2px;
                        margin-top: -5px;
                        width: 17px;
                        height: 7px;
                        border-top: solid 1px currentColor;
                        border-bottom: solid 1px currentColor;
                    }

                    .left-align.icon:before {
                        content: '';
                        position: absolute;
                        top: 2px;
                        left: 0;
                        width: 11px;
                        height: 5px;
                        border-top: solid 1px currentColor;
                        border-bottom: solid 1px currentColor;
                    }

                    .r-selectAlign-c {
                        position: relative;
                        flex: 1;
                        display: flex;
                        justify-content: center;

                        .center-align.icon {
                            color: #000;
                            position: absolute;
                            // margin-left: 2px;
                            margin-top: -5px;
                            width: 17px;
                            height: 7px;
                            border-top: solid 1px currentColor;
                            border-bottom: solid 1px currentColor;
                        }

                        .center-align.icon:before {
                            content: '';
                            position: absolute;
                            top: 2px;
                            left: 3px;
                            width: 11px;
                            height: 5px;
                            border-top: solid 1px currentColor;
                            border-bottom: solid 1px currentColor;
                        }

                    }

                    .r-selectAlign-r {
                        position: relative;

                        .right-align.icon {
                            color: #000;
                            position: absolute;
                            margin-left: -17px;
                            margin-top: -5px;
                            width: 17px;
                            height: 7px;
                            border-top: solid 1px currentColor;
                            border-bottom: solid 1px currentColor;
                        }

                        .right-align.icon:before {
                            content: '';
                            position: absolute;
                            top: 2px;
                            right: 0;
                            width: 11px;
                            height: 5px;
                            border-top: solid 1px currentColor;
                            border-bottom: solid 1px currentColor;
                        }

                    }

                    .otherStyle {
                        cursor: pointer;

                        &:hover {
                            .icon {
                                color: $blueColor1;
                            }
                        }
                    }

                }

                .item-r {

                    .activemaincolor {
                        color: $blueColor1 !important;
                    }

                    .r-fontFamily {
                        margin-bottom: 20px;
                        display: flex;
                        align-items: center;

                        .selectcolor {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-left: 10px;
                        }
                    }

                    .previewtxt {
                        font-family: var(--fontName);
                        margin-bottom: 20px;
                        color: var(--fontColor);
                    }

                    .r-StyleOne {
                        display: flex;
                        align-items: center;

                        .r-StyleOne-size {
                            margin-right: 30px;
                        }

                        .r-StyleOne-other {
                            display: flex;
                            align-items: center;
                            border-radius: 4px;
                            border: 1px solid #DCDFE6;

                            .item-other {
                                font-size: 14px;
                                line-height: 26px;
                                width: 28px;
                                text-align: center;
                                cursor: pointer;
                                transition: all .3s ease;

                                &:hover {
                                    color: $blueColor1;
                                    transition: all .3s ease;
                                }

                                &:last-child {
                                    font-style: italic;
                                }
                            }
                        }
                    }

                    .r-StyleTwo {
                        margin-top: 20px;
                        width: 500px;
                        display: flex;
                        align-items: center;

                        span {
                            font-size: 14px;
                            color: #606266;
                        }

                        .r-StyleTwo-center {
                            margin-left: 20px;
                            flex: 1;
                            margin-right: 10px;
                        }
                    }

                    .itemtemplatebox {
                        display: flex;
                        align-items: center;
                    }

                    .itemtemplate {
                        width: 34px;
                        height: 34px;
                        // background: #272C32;
                        text-align: center;
                        line-height: 34px;
                        color: #fff;
                        font-size: 20px;
                        cursor: pointer;
                        text-shadow: -1px 1px 0 var(--txtcolorshadow), 1px 1px 0 var(--txtcolorshadow), 1px -1px 0 var(--txtcolorshadow), -1px -1px 0 var(--txtcolorshadow);
                    }

                    .itemtemplatefontweight {
                        display: flex;
                        align-items: center;
                        width: 500px;
                        margin-top: 20px;

                        span {
                            font-size: 14px;
                            color: #606266;
                        }

                        .selectweight {
                            margin-left: 20px;
                            flex: 1;
                        }

                        .itemtemplatecolor {
                            margin-left: 10px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                }
            }

            .buttonGroup {
                margin-bottom: 60px;
            }
        }
    }

}
</style>
<style>
/* 阿里妈妈刀隶体 */
@font-face {
    font-family: 'ALIDL';
    src: url('../../../assets/iconfont/otherfonts/6HTptNGUDiOl.woff') format('woff'),
        url('../../../assets/iconfont/otherfonts/6HTptNGUDiOl.woff2') format('woff');
}

.ALIDLFONTS {
    font-family: 'ALIDL';
}

/* 阿里妈妈方圆体 */
@font-face {
    font-family: 'ALIFYT';
    src: url('../../../assets/iconfont/otherfonts/Pts4pzMr2Uwb.woff') format('woff'),
        url('../../../assets/iconfont/otherfonts/Pts4pzMr2Uwb.woff2') format('woff');
}

.ALIFYTFONTS {
    font-family: 'ALIFYT';
}

/* 阿里妈妈东方大楷 */
@font-face {
    font-family: 'ALIDFDY';
    src: url('../../../assets/iconfont/otherfonts/70rqfxRl5Rlm.woff') format('woff'),
        url('../../../assets/iconfont/otherfonts/70rqfxRl5Rlm.woff2') format('woff');
}

.ALIDFDYFONTS {
    font-family: 'ALIDFDY';
}

/* 钉钉进步体 */
@font-face {
    font-family: 'ALIDDJ';
    src: url('../../../assets/iconfont/otherfonts/TFPY7rLJnydP.woff') format('woff'),
        url('../../../assets/iconfont/otherfonts/TFPY7rLJnydP.woff2') format('woff');
}

.ALIDDJFONTS {
    font-family: 'ALIDDJ';
}

/* 站酷高端黑 */
@font-face {
    font-family: 'ALIZK';
    src: url('../../../assets/iconfont/otherfonts/Mn2pImDGlnHu.woff') format('woff'),
        url('../../../assets/iconfont/otherfonts/Mn2pImDGlnHu.woff2') format('woff');
}

.ALIZKFONTS {
    font-family: 'ALIZK';
}
</style>
